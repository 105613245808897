.grouping {
  @media screen and (min-width: ($tablet-md)) {
    border-radius: $size-4;
    overflow: hidden;
  }

  a {
    text-decoration: none;
  }

  .sect-title {
    font-weight: 700;
    display: inline-block;
    margin-bottom: $size-16;
    padding: 0;
    border: none;
    background: linear-gradient(to right, $text 0%, $text 60%, transparent 60%, transparent 100%);
    background-size: $size-16 $size-1;
    background-repeat: repeat-x;
    text-decoration: none;
    background-position: bottom;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: $size-8;
    margin: 0;
    padding: 0 0 0 $size-24;
  }

  .bar {
    padding: $size-8 $size-20;
    display: flex;
    flex-direction: column;
    gap: $size-8;

    p {
      margin: 0;
      line-height: 1.3;
    }
  }

  .heading {
    font-size: $size-24;
    font-weight: 700;

    @media screen and (max-width: ($tablet-md - 1)) {
      font-size: $size-20;
    }
  }

  .group-padding {
    padding: $size-16;

    @media screen and (min-width: ($tablet-md)) {
      border-radius: 0 0 $size-4 $size-4;
      border-left: $size-2 solid $base-cool;
      border-right: $size-2 solid $base-cool;
      border-bottom: $size-2 solid $base-cool;
    }

    &.border-purple {
      border-color: $tertiary-lighter;
    }

    &.border-light-green {
      border-color: $secondary-extra-light;
    }
  }
}
