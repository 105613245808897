@import "../global/header";
@import "../colors";
#search-results-page {
  a {
    &.back-link {
      color: $primary;
      position: relative;
      display: inline-block;
      margin-left: $size-32;
      margin-top: $size-16;
      margin-bottom: $size-16;
      text-decoration: none;
      border-bottom: $size-1 solid $primary;

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -$size-32;
        z-index: 10;
      }

      &:visited {
        color: $primary;
      }
    }
  }

  label {
    margin: 0;
    font-weight: bold;
  }

  select {
    border: $black solid 2px;
    border-radius: 4px;
  }

  .card {
    border: 1px solid $light-grey;
    border-radius: 5px;
    margin-top: $size-16;
  }
}

#drawer-btn-container {
  position: absolute;
  z-index: 1000;
  bottom: 0;
  left: 0;
  background-color: $secondary-light;
  display: flex;
  justify-content: space-between;
  padding: $size-16;
  width: 100%;
  border-radius: $size-8 $size-8 0 0;
  display: flex;
  gap: $size-16;
  justify-content: center;

  button {
    border: 2px solid $secondary;
    border-radius: 8px;
    font-weight: 700;
    padding: $size-16 0;
    text-align: center;
    width: 150px;
  }

  #submit-button {
    background-color: $secondary;
    color: $white;

    &:hover {
      background-color: $secondary-dark;
      border: 2px solid $secondary-dark;
    }
  }

  #reset-button {
    background-color: $white;
    color: $secondary;

    &:hover {
      background-color: $gray-cool-10;
    }
  }
}

#drawer-container {
  background-color: $secondary-lighter;
  overflow: auto;
  padding: $size-16;
  width: 100%;

  @media screen and (min-width: ($tablet-md)) {
    min-height: 100svh;
  }

  .checkbox-container,
  .MuiIconButton-label {
    &:hover {
      cursor: pointer;
    }

    label {
      &:hover {
        cursor: pointer !important;
      }
    }

    svg {
      path {
        fill: $primary-dark;
      }
    }
  }

  .field-group {
    margin-bottom: 24px;
  }

  .filter-input {
    background-color: $white;
    width: 100%;
  }

  .label-container {
    label {
      font-size: 1rem;
      font-weight: 700;
      line-height: 24px;
    }
  }
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 3.25rem;
    height: 1.75rem;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;

    &:focus {
      + .slider {
        outline: $size-4 solid $blue-60v;
        outline-offset: $size-4;
      }
    }
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $base-light;
    border: 1px solid $base;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    transition-property: transform, background-color;
  }

  $slider-size: 20px;

  .slider:before {
    position: absolute;
    content: "";
    height: $slider-size;
    width: $slider-size;
    left: 3px;
    bottom: 3px;
    background-color: $gray-cool-50;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .isChecked:before {
    background-color: $white;
  }

  input:checked + .slider {
    background-color: $secondary;
    border: 1px solid $secondary;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $gray-cool-50;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX($slider-size);
    -ms-transform: translateX($slider-size);
    transform: translateX(24px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .zip-miles-group {
    display: flex;
    justify-content: space-between;
    max-width: $size-340;

    .conjunction-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: 700;
      padding: 0;
      height: 56px;
    }

    .field-group {
      margin: 0;
    }

    .filter-input {
      max-width: 125px;
    }
  }
}

#drawer-header {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: $secondary-lighter;
  display: flex;
  justify-content: space-between;
  padding: $size-16;
  width: 100%;

  h2 {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }

  button {
    background-color: $secondary-lighter;
    border: none;
    color: $blue-60v;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0;
  }
}

#filter-button-container {
  align-items: center;
  width: 100%;

  @media screen and (max-width: ($tablet-md - 1)) {
    margin-bottom: $size-16;
    width: $size-40;
  }

  button {
    position: relative;
    align-items: center;
    background-color: $white;
    border: 2px solid $base-light;
    border-radius: 0.25rem;
    color: $base;
    gap: 0.5rem;
    display: flex;
    justify-content: center;
    padding: 0.75rem 1.25rem;
    width: 100%;

    @media screen and (max-width: ($tablet-md - 1)) {
      width: $size-40;
      height: $size-40;
      padding: 0;
    }

    &:hover {
      color: $blue-warm-70v;
      border: 2px solid $blue-warm-70v;
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
    }
  }

  .mobile-only {
    position: absolute;
    top: -$size-12;
    right: -$size-12;
    background-color: $accent-cool-darker;
    color: $white;
    font-size: $size-12;
    height: $size-24;
    width: $size-24;
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 1;
    display: flex;
    line-height: 1;
    border-radius: 100%;

    @media screen and (min-width: ($tablet-md)) {
      display: none;
    }
  }
}

#filter-form-container {
  padding: $size-50 0 $size-75 0;
}

#search-results-heading {
  margin: $size-16 0 0 0;
}

#search-header-container {
  display: flex;
  margin-bottom: $size-28;
  margin: $size-16 0;
  align-items: flex-end;
  justify-content: space-between;
  gap: $size-16;

  @media screen and (max-width: ($tablet-md - 1)) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  h2 {
    @media screen and (min-width: ($tablet-md)) {
      width: 100%;
    }
  }

  .usa-search {
    max-width: none;
    width: 100%;

    @media screen and (min-width: ($tablet-md)) {
      max-width: $size-416;
    }

    @media screen and (max-width: ($tablet-md - 1)) {
      display: none;
    }

    input {
      height: $size-40;
      max-width: none;
      width: calc(100% - $size-48);
      border-radius: $size-4 0 0 $size-4;
    }

    button {
      height: $size-40;
      justify-content: center;
      background-color: $secondary;

      &:hover {
        background-color: $secondary-dark;
      }
    }

    input {
      border-color: $base-light;
      border-width: $size-2;
    }
  }
}

#selects-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .input-container {
    width: 50%;

    .usa-label {
      color: $base-dark;
    }

    .usa-select {
      border-color: $base-light;
      color: $base;

      @media screen and (min-width: ($tablet-md)) {
        min-width: $size-200;
      }
    }
  }

  .input-container + .input-container {
    margin-left: $size-16;
  }
}

.breadcrumb-container {
  .usa-breadcrumb {
    padding-top: $size-16;
    margin-bottom: $size-16;
  }

  .usa-breadcrumb__list {
    display: none;
  }
}
#search-filters-container {
  .chip-container {
    @media screen and (max-width: ($tablet-lg - 1)) {
      display: none;
    }

    .chips {
      display: flex;
      flex-wrap: wrap;
      gap: $size-8;
      margin-top: $size-16;
    }

    .tag-item {
      font-size: $size-14;
      padding: $size-4 $size-8;
    }

    .clear-filters-button {
      color: $error;
      align-items: center;
      display: flex;
      gap: $size-8;
      padding: 0;
      font-weight: bold;
      svg {
        margin-bottom: $size-2;
      }
    }
  }
}
@media (min-width: $tablet-md) {
  #drawer-container {
    width: $mobile-md;
  }

  #filter-button-container {
    button {
      justify-content: left;
      width: auto;
    }
  }

  #search-filters-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: $size-16;
  }
}

@media (min-width: $tablet-md) {
  .breadcrumb-container {
    margin-top: $size-16;

    .usa-breadcrumb__list {
      display: block;
    }

    .back-link {
      display: none !important;
    }
  }
}

.filter-drawer {
  .filter-button-row {
    @media screen and (max-width: ($tablet-md - 1)) {
      display: flex;
      gap: $size-12;
    }
  }
  .usa-search {
    max-width: none;
    width: 100%;

    @media screen and (min-width: ($tablet-md)) {
      display: none;
    }

    input {
      max-width: none;
      height: $size-40;
      border-radius: $size-4 0 0 $size-4;
      width: calc(100% - $size-48);
    }

    button {
      height: $size-40;
      justify-content: center;
      background-color: $secondary;

      &:hover {
        background-color: $secondary-dark;
      }
    }

    input {
      border-color: $base-light;
      border-width: $size-2;
    }
  }
}

.search-drawer {
  .MuiDrawer-paper {
    border-radius: 0 $size-8 $size-8 0;
    background-color: $secondary-lighter;

    @media screen and (max-width: ($tablet - 1)) {
      border-radius: $size-8 $size-8 0 0;
      height: 90svh;
    }
  }
}
