.training-comparison {
  position: fixed;
  bottom: 0;
  z-index: 10; // overlap fixed-wrapper
  left: 0;

  &.expanded {
    width: 100%;
    height: calc(100% - #{$header-height});
    overflow: hidden scroll;
  }

  .grid-container {
    display: grid;
    grid-column-gap: 5.75%;

    &.expanded {
      overflow: hidden;
    }

    &:not(.expanded) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .comparison-item {
    position: relative;
    background: white;
    border: 1px solid $light-grey;
    padding: 8px;
    min-width: 100%;
    width: 100%;
    height: 75px;

    &.empty {
      background: rgba(white, 0.4);
    }

    .btn-remove {
      position: absolute;
      top: -8px;
      right: -8px;
      min-width: auto;
      padding: 0;
      font-size: 20px;
    }
  }

  .truncated {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1;
  }

  .button-container {
    grid-column: 1 / -1;
  }

  .btn-compare,
  .btn-collapse {
    margin-bottom: 0.5rem;
  }

  @media (max-width: $tablet-md) {
    .icon-flip {
      transform: scaleX(-1);
    }
  }

  @media (min-width: $tablet-md) {
    &.expanded {
      max-height: 85%;
      height: auto;

      .button-container {
        grid-column: 2;
      }
    }

    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr auto;
      grid-column-gap: 5.75%;

      &.expanded {
        grid-template-columns: 1fr auto;
        overflow: none;
      }
    }

    .comparison-item {
      height: auto;
    }

    .btn-compare,
    .btn-collapse {
      margin-bottom: 0.5rem;
    }

    .button-container {
      grid-column: 4;
    }
  }
}

.comparison-table-mobile {
  width: 102%;
  margin-left: -1%;

  &.wide {
    width: 152%;
  }

  &.scroll-end {
    margin-left: -51%;
  }

  .btn-details {
    min-width: 113px;
  }
}

.comparison-table {
  .tbl-header {
    width: 126px;
  }

  @media (max-width: $tablet-xl) {
    .btn-details {
      min-width: 113px;
    }
  }
}
