header {
  &.header {
    background-color: $white;
    width: 100%;
    z-index: 12;
    min-height: 187px;

    @media screen and (max-width: ($desktop)) {
      min-height: 135px;

      background-color: $dark-blue;
      color: $white;
    }
  }

  .basic-logo {
    a {
      display: flex;
      align-items: center;
      color: $ink;
      text-decoration: none;
      padding: $size-16 0;
      width: calc(100% - 55px);

      @media screen and (min-width: ($desktop + 1)) {
        width: 50%;
      }
    }

    h1 {
      font-size: 28px;

      @media screen and (min-width: ($desktop + 1)) {
        width: calc(100% - 45px);
        margin-left: 10px;
      }

      @media screen and (max-width: ($desktop)) {
        color: $white;
        font-size: $size-14;
      }
    }

    img {
      width: $size-48;
      height: auto;

      @media screen and (max-width: ($desktop)) {
        display: none;
      }
    }

    button {
      width: 55px;
      border: $size-2 solid $white;
      padding: $size-2;
      border-radius: 5px;

      span {
        margin: 0 auto;
        font-size: 2rem;
      }
    }

    button {
      .toggle {
        height: $size-32;
        width: 51px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        transform-origin: center;
        &.open {
          transform: rotate(90deg);
          > span {
            &:nth-child(1) {
              transform: rotate(45deg);
              top: 7px;
            }

            &:nth-child(2) {
              transform: scale(0);
            }

            &:nth-child(3) {
              transform: rotate(-45deg);
              top: -7px;
            }
          }
        }

        > span {
          display: block;
          width: 25px;
          height: $size-3;
          position: relative;
          border-radius: 5px;
          top: 0;
          background-color: $white;
          margin: $size-2 auto;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
        }
      }
    }
  }

  .mobile-menu {
    padding: $size-8 $size-16;
  }

  .main-nav {
    background-color: $dark-blue;
    min-height: 54px;

    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style-type: none;

      @media screen and (min-width: ($desktop + 1)) {
        gap: 2.5rem;
      }

      @media screen and (max-width: ($desktop)) {
        flex-direction: column;
      }
    }
  }

  .main-nav__container {
    background-color: $dark-blue;

    a,
    button {
      color: $white;
      display: block;
      text-decoration: none;
      padding: 1.18rem 0;
      font-size: 0.9rem;
      position: relative;

      @media screen and (max-width: ($desktop)) {
        padding: 0.95rem 0;
      }

      > span {
        display: flex;
        align-items: center;

        span {
          font-size: $size-16;
          margin-top: -$size-2;
          margin-left: $size-2;
        }

        + .indicator {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-size: $size-20;
          right: 0;

          @media screen and (min-width: ($desktop + 1)) {
            right: -$size-20;
          }
        }
      }

      &:hover {
        @media screen and (min-width: ($desktop + 1)) {
          &::after {
            content: "";
            position: absolute;
            bottom: 10px;
            width: 100%;
            left: 0;
            height: $size-2;
            background-color: $white;
          }
        }
      }
    }

    .main-nav__links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      width: 100%;
      max-width: $desktop-xl;

      @media screen and (max-width: ($desktop)) {
        flex-direction: column;
      }
    }
  }

  li {
    &.has-sub {
      position: relative;
      z-index: 1;

      .sub-menu {
        background: $white;
        box-shadow: $shadow-1;
        flex-direction: column;
        gap: 0;

        @media screen and (min-width: ($desktop + 1)) {
          width: 300px;
          position: absolute;
        }

        @media screen and (max-width: ($desktop)) {
          margin-bottom: $size-16;
        }

        a {
          color: $header-black;
          padding: 0.5rem $size-16;

          &:hover {
            @media screen and (min-width: ($desktop + 1)) {
              background-color: $header-black;
              color: $white;
            }

            &:after {
              @media screen and (min-width: ($desktop + 1)) {
                content: none;
              }
            }
          }
        }
      }

      button {
        @media screen and (max-width: ($desktop)) {
          width: 100%;
        }
      }
    }
  }

  .contact-links {
    display: flex;
    align-items: center;
    gap: $size-16;
    margin-right: $size-16;
  }

  .main-nav__container {
    .sign-up-toggle {
      background-color: transparent;
      color: $white;
      border: $size-2 solid $white;
      padding: $size-8 $size-16;
      font-weight: normal;
      max-height: $size-40;

      &:hover {
        background-color: $white;
        color: $primary;

        &:after {
          content: none;
        }
      }
    }
  }
}

.below-banners {
  min-height: calc(100% - #{$footer-height});
  padding: 0 0 5rem;

  @media print {
    min-height: auto;
    padding-top: 0;
    padding-bottom: 0;
  }

  .footer-banner,
  .footer-cta {
    margin: 5rem 0 -5rem;
  }
}

.skip-nav {
  position: absolute;
  top: -100%;
  left: 0;
  width: auto;
  background-color: $white;
  color: $ink;
  padding: $size-16;
  z-index: 100;

  &:focus,
  &:hover,
  &:active {
    top: 0;
  }
}

.signUpModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  &.open {
    pointer-events: all;

    .overlay {
      opacity: 1;
      transition-delay: 0;
    }

    .modal {
      transition-delay: $transition-speed / 2;
      opacity: 1;
      top: 0;
    }
  }

  .overlay {
    opacity: 0;
    transition-delay: $transition-speed / 2;
    background-color: rgba(0, 0, 0, 0.5);
    transition-duration: $transition-speed;
    position: fixed;
    top: 0;
    z-index: 1;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .modal {
    opacity: 0;
    transition-delay: 0;
    transition-duration: $transition-speed;
    top: $size-40;
    background-color: $white;
    overflow: auto;
    max-height: 100svh;
    -webkit-overflow-scrolling: touch;
    position: relative;
    z-index: 2;
    max-width: $size-520;
    color: $black;
    border-radius: $size-8;
    padding: $size-32;

    a {
      color: $primary;
      text-decoration: none;
      display: inline;
    }

    button {
      padding: $size-8 $size-16;

      &.usa-button:not(.usa-button--unstyled):not(.close) {
        background-color: $primary;
        &:hover {
          background-color: $primary-dark;
        }
      }

      &:hover {
        &:after {
          content: none;
        }
      }

      &.close {
        padding: 0;
        position: absolute;
        color: $base;
        top: $size-16;
        right: $size-16;
      }
    }

    .heading {
      font-weight: 700;
      font-size: $size-20;
    }

    .instruction {
      display: block;
      font-size: $size-14;
      margin: $size-16 0;
    }

    .row {
      display: flex;
      gap: $size-16;

      > * {
        flex: 1;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      gap: $size-20;

      .usa-alert--error,
      .usa-alert--success {
        margin-top: 0;

        .usa-alert__body {
          &:before {
            top: $size-13;
          }
        }
      }
    }

    label {
      display: flex;
      flex-direction: column;
      gap: $size-8;
      font-size: $size-16;

      &.error {
        input {
          border-color: $error;
        }
      }

      &.email {
        > div {
          position: relative;
        }

        input {
          padding-left: $size-40;
        }

        svg {
          position: absolute;
          top: 50%;
          left: $size-16;
          transform: translateY(-50%);
        }
      }

      > span {
        font-weight: 700;
      }

      .errorMessage {
        font-weight: 700;
        color: $error;
      }
    }

    input {
      width: 100%;
      min-height: $size-56;
      border-radius: $size-4;
      border: $size-2 $base-light solid;
      padding: 0 $size-16;
    }

    .usa-alert__heading {
      font-weight: 700;
      font-size: $size-22;
    }
  }

  .spinner {
    position: relative;
    transform-origin: center;
    height: 20px;
    width: 20px;
    transform: translate(-50%, -50%);
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
