.training-detail {
  main {
    button {
      &:not(.usa-button) {
        color: $text;
      }
    }
  }

  .container {
    &:after,
    &:before {
      content: none;
    }

    &.main-section {
      overflow-x: hidden;

      @media screen and (max-width: ($tablet-md - 1)) {
        padding: 0;
      }
    }
  }

  .crumb-container {
    @media screen and (min-width: ($mobile-lg)) {
      padding: $size-24 0;
    }

    .container {
      display: flex;
      gap: $size-8;
      justify-content: space-between;
      align-items: center;

      nav {
        width: 100%;

        @media screen and (min-width: ($tablet-md)) {
          width: calc(100% - ($size-300 + $size-8));
        }

        @media screen and (max-width: ($tablet-md - 1)) {
          max-width: calc(100% - $size-48);
        }
      }

      .form-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        background-color: $black;
        opacity: 0;
        pointer-events: none;
        transition-duration: 0.3s;

        @media screen and (min-width: ($tablet-md)) {
          display: none;
        }

        &.open {
          pointer-events: all;
          opacity: 0.5;
        }
      }

      .search-toggle {
        position: relative;
      }

      .close-button {
        position: absolute;
        z-index: 100;
        top: $size-10;
        right: $size-8;
        color: $text;
      }

      .usa-search {
        position: relative;
        max-width: none;
        width: 100%;

        @media screen and (min-width: ($tablet-md)) {
          max-width: $size-300;
        }

        @media screen and (max-width: ($tablet-md - 1)) {
          transition-duration: 0.3s;
          bottom: -$size-300;
          background-color: $secondary-extra-light;
          position: fixed;
          padding: $size-24 $size-16 $size-32;
          display: flex;
          z-index: 100;
          flex-wrap: wrap;
          left: 0;

          &.open {
            bottom: 0;
          }
        }

        label {
          font-size: $size-24;
          font-weight: 700;
          margin-bottom: $size-8;
        }

        input {
          height: $size-40;
          max-width: none;
          width: calc(100% - $size-48);
          border-radius: $size-4 0 0 $size-4;

          + button {
            height: $size-40;
            justify-content: center;
            background-color: $secondary;

            &:hover {
              background-color: $secondary-dark;
            }
          }
        }

        input {
          border-color: $base-light;
          border-width: $size-2;
        }
      }
    }
  }

  .title-box {
    background-color: $secondary-lighter;
    padding: $size-16 0;

    @media screen and (min-width: ($tablet-md)) {
      top: 0;
      z-index: 10;
      position: sticky;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $size-8;
    }

    h1 {
      font-size: $size-32;
      font-weight: 600;

      @media screen and (max-width: ($tablet-md - 1)) {
        font-size: $size-24;
      }
    }

    ul {
      display: flex;
      gap: $size-16;

      li {
        position: relative;

        span {
          position: absolute;
          top: calc(100% + $size-4);
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .heading-box {
      display: flex;
      flex-direction: column;
      gap: $size-8;
    }
  }

  .usa-button {
    &.report {
      max-width: calc(100% - $size-32);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .fact-item {
    margin-bottom: $size-12;
    display: flex;
    align-items: flex-start;
    gap: $size-8;

    svg {
      width: $size-18;
      height: auto;
    }

    .copy {
      display: flex;
      flex-direction: column;
      gap: $size-4;
      max-width: calc(100% - $size-24);

      .label {
        line-height: 1.2;

        button {
          padding: 0;
        }

        strong {
          display: inline-flex;
          align-items: center;
          gap: $size-2;
        }

        + p {
          margin: 0;
        }
      }
    }
  }

  .disclaimer {
    font-size: $size-14;
    line-height: 1.2;
  }

  .save-controls {
    @media screen and (max-width: ($tablet-md - 1)) {
      flex-direction: column;
    }
    li {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $size-32;
        width: $size-32;
        border-radius: $size-4;

        &.green {
          background-color: $secondary;

          svg {
            color: $white;
          }

          .indicator {
            display: inline-block;
            background-color: $secondary;
          }

          &:hover {
            background-color: $secondary;

            svg {
              color: $white;
            }
          }
        }

        &:hover {
          background-color: $black;
          svg {
            color: $white;
          }

          .indicator {
            display: inline-block;
          }
        }

        .indicator {
          display: none;
          background-color: $black;
          white-space: nowrap;
          color: $white;
          font-size: $size-12;
          padding: $size-2;
          border-radius: $size-4;
        }
      }
    }
  }

  .funding-content {
    display: flex;
    flex-direction: column;
    gap: $size-32;

    div {
      display: flex;
      flex-direction: column;
      gap: $size-16;
    }

    a:not(p a) {
      margin-top: 0;
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      text-decoration: underline;
      text-underline-offset: $size-4;
      text-decoration-thickness: $size-2;
      gap: $size-8;

      svg {
        margin-bottom: $size-4;
      }
    }
  }

  .grey-line {
    background-color: $base-lighter;
    height: $size-1;
  }

  .cost-item {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: $size-1;
      border-top: $size-1 dashed $base-lighter;
    }

    span {
      position: relative;
      z-index: 2;
      background-color: $white;

      &:first-child {
        padding-right: $size-8;
      }

      &:last-child {
        padding-left: $size-8;
      }
    }
  }

  .cip.panel,
  .cip.overlay {
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }

  .cip.panel {
    position: fixed;
    border-left: $size-4 solid $secondary-darker;
    top: 0;
    right: -100%;
    background: $secondary-extra-light;
    padding: 3rem;
    max-width: $size-480;
    width: 100%;
    height: 100%;
    z-index: 12;
    box-shadow: $shadow-5;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media screen and (max-width: ($tablet - 1)) {
      padding: 3rem $size-16;
    }

    h3 {
      font-size: $size-24;
      font-weight: 600;
      margin-bottom: $size-32;
    }

    &.open {
      right: 0;
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: $size-16;
      cursor: pointer;
      z-index: 11;
    }
  }

  .cip.overlay {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background: $ink;
    opacity: 0;
    z-index: 11;

    &.open {
      pointer-events: all;
      opacity: 0.5;
    }
  }
}

.MuiPaper-root.MuiDrawer-paper {
  &:focus {
    outline: none;
  }
}

.contextual-info-panel {
  max-width: $size-480;
  border-left: $size-4 solid $secondary-darker;
}
