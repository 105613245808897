.stat-block,
.county-block {
  padding: $size-12;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: $size-4;
  font-size: $size-16;
  min-height: $size-96;
  gap: $size-16;
  width: 100%;

  .stat-block-number {
    font-weight: 600;
  }

  .contextual-link-text {
    border: none;
    background: linear-gradient(to right, $text 0%, $text 60%, transparent 60%, transparent 100%);
    background-size: $size-16 $size-1;
    background-repeat: repeat-x;
    text-decoration: none;
    background-position: bottom;
  }
}

.stat-block {
  background-color: $accent-cool-light;

  @media screen and (min-width: ($tablet-md)) {
    max-width: $size-200;
  }
}

.county-block {
  background-color: $accent-warm-light;

  @media screen and (min-width: ($tablet-md)) {
    max-width: $size-300;
  }

  strong {
    display: inline-flex;
    align-items: center;
    gap: $size-8;
  }

  a {
    color: $primary-dark;
  }
}
