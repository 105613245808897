@import "system";
@import "colors";
@import "spacing";
@import "borders";
@import "flex";
@import "typography";
@import "variables";
@import "table";
@import "css/bootstrap.css";

@import "faq-breadcrumb";
@import "training-comparison";

// PAGES
@import "pages/career-navigator";
@import "pages/career-pathways";
@import "pages/contact-page";
@import "pages/home-page";
@import "pages/in-demand-page";
@import "pages/industry-page";
@import "pages/landing-page";
@import "pages/not-found-page";
@import "pages/occupation-page";
@import "pages/search-results-page";
@import "pages/training-explorer";
@import "pages/training-page";

// GLOBAL
@import "global/betaBanner";
@import "global/footer";
@import "global/globalHeader";
@import "global/header";
@import "global/subFooter";

// MODULES
@import "modules/accordion";
@import "modules/backToTop";
@import "modules/breadcrumbs";
@import "modules/buttons";
@import "modules/cta";
@import "modules/cta";
@import "modules/drop-selector";
@import "modules/error-message";
@import "modules/financial-resource";
@import "modules/footer-banner";
@import "modules/grouping";
@import "modules/how-to-button";
@import "modules/iconCard";
@import "modules/info-blocks";
@import "modules/mixins";
@import "modules/path-menu";
@import "modules/section-heading";
@import "modules/single-path";
@import "modules/stat-block";
@import "modules/tags";

// COMPONENTS
@import "components/alertBar";
@import "components/cardRow";
@import "components/career-map";
@import "components/career-pathways";
@import "components/ctaBanner";
@import "components/faq-collection";
@import "components/filterControls";
@import "components/footer-cta";
@import "components/homeBanner";
@import "components/how-to";
@import "components/industry-block";
@import "components/industry-field-drawer";
@import "components/industry-selector";
@import "components/interrupter";
@import "components/introBlocks";
@import "components/labelBox";
@import "components/occupation-block";
@import "components/page-banner";
@import "components/pagination";
@import "components/pathway-menus";
@import "components/resource-filter";
@import "components/resource-links";
@import "components/resourceCard";
@import "components/resourceList";
@import "components/river";
@import "components/updateNotifier";
@import "components/search-block";
@import "components/stepper";
@import "components/tab-content";

button:not([disabled]):focus,
input:not([disabled]):focus,
select:not([disabled]):focus,
textarea:not([disabled]):focus,
[contentEditable="true"]:focus,
[href]:focus,
[tabindex]:focus,
iframe:focus {
  outline: $size-4 solid $primary;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
  height: 100%;
}

#root {
  min-height: 100%;
  height: 100%;
}

#root > div {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  overflow-y: scroll;
}

button {
  font: unset;
  text-align: unset;
  background-color: unset;
  border: unset;
  cursor: pointer;
}

p {
  max-width: none !important;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

.visually-hidden {
  width: 0px;
  height: 0px;
  visibility: hidden;
}

a.link-format-black {
  color: $ink !important;
  text-decoration: none;
}

a,
a.usa-link {
  color: $primary;

  &:visited {
    color: $primary;
  }

  &:hover {
    @media screen and (min-width: ($desktop)) {
      color: $primary-vivid;
    }
  }
}

button.link-format-blue,
a.link-format-blue {
  color: $primary !important;
}

.button-size-full {
  min-width: 100%;
}

.contextual-info-panel {
  width: 33vw;
  padding-left: 3em;
  padding-right: 2em;
}

@media (max-width: $tablet-md) {
  .contextual-info-panel {
    width: 66vw;
    padding-left: 2em;
    padding-right: 1.5em;
  }
}

.contextual-link-button {
  font-weight: inherit !important;
  font-style: inherit !important;
  cursor: pointer;
  color: #000000;
}

.contextual-link-text {
  border-bottom-width: 2px;
  border-bottom-style: dashed;
  border-color: #000000;
}

.two-column-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.tag {
  border-radius: $size-100;
  width: max-content;
  padding: $size-5 $size-16 $size-5 $size-12;
  display: flex;
  gap: $size-6;
}

.MuiOutlinedInput.input {
  padding: 0px 0px;
}

.language-switch-buttton {
  background-color: $green-cool-50v;
  position: fixed;
  bottom: $size-16;
  left: $size-16;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.embed-youtube {
  position: relative;
  padding-bottom: 56.25%; /* - 16:9 aspect ratio */
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.embed-youtube iframe,
.embed-youtube object,
.embed-youtube embed {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

header {
  .usa-nav-container {
    max-width: $desktop-xl;
    padding-right: 15px;
    padding-left: 15px;
  }
}

ul,
ol {
  &.unstyled {
    margin: 0;
    list-style: none;
    padding-left: 0;
  }
}

.image-text {
  display: flex;
  align-items: center;
  gap: 2rem;

  @media screen and (max-width: ($tablet)) {
    flex-direction: column;
  }

  .description {
    max-width: 400px;
    font-size: 1.5rem;

    p {
      line-height: 1.2;
    }
  }
}

.container {
  width: 100%;
  max-width: $desktop-xl;
}

.flex-card-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;

  > * {
    max-width: 260px;
  }
}

.section-padding {
  padding: 4rem 0;

  @media screen and (max-width: ($tablet-md)) {
    padding: 2rem 0;
  }
}

.mobile-only {
  display: none;

  @media screen and (max-width: ($tablet-md)) {
    display: block;
  }
}

.desktop-only {
  display: block;

  @media screen and (max-width: ($tablet-md)) {
    display: none;
  }
}

.beta-alert {
  margin-top: 0;
}

.copy-block {
  display: flex;
  flex-direction: column;
  gap: $size-16;
  margin-top: $size-32;

  > * {
    margin: 0;
  }

  ul,
  ol {
    display: flex;
    flex-direction: column;
    gap: $size-4;
    margin: 0;

    ul,
    ol {
      margin-top: $size-16;
      margin-bottom: $size-16;
    }
  }

  h1,
  h2 {
    margin-bottom: $size-16;
  }

  h1 {
    font-size: $size-40;
  }

  h2 {
    font-size: $size-32;
  }

  h3 {
    font-size: $size-24;
  }

  h4 {
    font-size: $size-22;
  }

  h5 {
    font-size: $size-20;
  }

  h6 {
    font-size: $size-18;
  }
}
