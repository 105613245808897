.bg-footer-grey {
  background-color: $footer-grey;
}

.bg-light-orange {
  background-color: $accent-warm-light;
}

.bg-light-yellow {
  background-color: $accent-warm-lighter;
}

.bg-light-green {
  background-color: $secondary-extra-light;
}

.bg-light-purple {
  background-color: $tertiary-lighter;
}

.bg-light-purple-30 {
  background: rgba($tertiary-lighter, 0.3);
}

.bg-light-purple-50 {
  background: rgba($tertiary-lighter, 0.5);
}

.bg-lighter-purple {
  background-color: $tertiary-lighter;
}

.bg-lightest-purple {
  background-color: $tertiary-lighter;
}

.bg-purple {
  background-color: $tertiary-lighter;
}

.bg-blue {
  background-color: $primary-lighter;
}

.bg-white {
  background-color: $white;
}

.black {
  color: $ink;
}

.blue {
  color: $primary;
}

.red {
  color: $error;
}

.green {
  color: $secondary;
}

.white {
  color: $white;
}

.indigo {
  color: $indigo;
}

.accessible-gray {
  color: $accessible-gray;
}

.bg-orange-tag {
  background-color: $orange;
}
